import React, { StrictMode } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from '@apollo/client'
import { BFF_BASE_URI } from '../../api/constants'
import { App } from './App'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

const link = ApolloLink.from([new HttpLink({ uri: BFF_BASE_URI })])
const apolloClient = new ApolloClient({
  name: 'spa-customer-sessions',
  link: link,
  cache: new InMemoryCache()
})

export const AppSetup = () => {
  return (
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <SnackBarProvider>
          <App />
        </SnackBarProvider>
      </ApolloProvider>
    </StrictMode>
  )
}
