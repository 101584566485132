import React from 'react'
import cx from 'classnames'
import { Variants, Sizes, Contrasts } from '../props'
import {
  VariantPropType,
  SizePropType,
  ContrastPropType,
  getBgColor
} from '../utils'
import { string } from 'prop-types'

export const ColorDot = ({
  variant = Variants.INACTIVE,
  size = Sizes.MEDIUM,
  contrast = Contrasts.LIGHT_BACKGROUND,
  testId = 'color-dot-indicator',
  title = 'Color dot indicator',
  className
}) => (
  <i
    data-testid={testId}
    role='img'
    title={title}
    className={cx(
      'inline-block rounded-full',
      getBgColor(variant, contrast),
      {
        'w-1 h-1': size === Sizes.SMALL,
        'w-2 h-2': size === Sizes.MEDIUM,
        'w-4 h-4': size === Sizes.LARGE
      },
      className
    )}
  ></i>
)

ColorDot.propTypes = {
  // The indicator state (defining the background color). Defaults to inactive.
  variant: VariantPropType,
  // Size of the indicator. Defaults to medium.
  size: SizePropType,
  // Tone of the color based on the background. Defaults to `lightBackground`.
  contrast: ContrastPropType,
  testId: string,
  title: string,
  className: string
}
