import { useReducer, useEffect } from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import {
  transformExpirationToRemainingTime,
  getRemainingTimeState,
  RemainingTimeStates
} from '../../utils/remainingTime'

export const INITIAL_STATE = {
  sessions: [],
  _currentRestaurantGuid: '',
  _currentManagementSetGuid: ''
}

export const Actions = Object.freeze({
  /**
   * Payload: sessions.
   */
  SET_SESSIONS: 'set_sessions',
  /**
   * Payload: nothing. Shouldn't be called externally.
   */
  UPDATE_TIMERS: 'update_timers',
  /**
   * Payload: updated session information.
   */
  EXTEND_SESSION: 'extend_session',
  /**
   * Payload: session id.
   */
  REMOVE_SESSION: 'remove_session'
})

const transformExpiration = (expiration) => {
  const remainingTime = transformExpirationToRemainingTime(expiration)
  return {
    expiration,
    remainingTime,
    isLow: getRemainingTimeState(remainingTime) <= RemainingTimeStates.LOW
  }
}

const initializeSession = (currentRxtGuid, currentMgmtSetGuid) => (session) => {
  return {
    ...session,
    ...transformExpiration(session.expiration),
    hasExpired: false,
    isActive:
      session.restaurant.guid === currentRxtGuid ||
      (currentMgmtSetGuid &&
        session.restaurant.managementGroup?.guid === currentMgmtSetGuid)
  }
}

const decreaseTimer = (session) => {
  /**
   * Instead of just removing the amount of time spent between intervals (in
   * useSessions), we are computing the new remaining time from the current
   * timestamp. This prevents issues with `setInterval` being a little bit
   * delayed when ran in the background, which would cause discrepancy.
   * 1 minute on earth is 60 seconds, 1 minute in background JS is 63 seconds.🤯
   * This may be affected by the load on the page (React, multiple SPAs, etc.).
   */
  const newRemainingTime = transformExpirationToRemainingTime(
    session.expiration
  )
  return {
    ...session,
    hasExpired: newRemainingTime === 0,
    remainingTime: newRemainingTime > 0 ? newRemainingTime : 0,
    isLow: getRemainingTimeState(newRemainingTime) <= RemainingTimeStates.LOW
  }
}

const reducer = (state, action) => {
  const { sessions, _currentRestaurantGuid, _currentManagementSetGuid } = state
  switch (action.type) {
    case Actions.SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload
          .filter((session) => session.restaurant) // filter out sessions where the full restaurant lookup failed
          .map(
            initializeSession(_currentRestaurantGuid, _currentManagementSetGuid)
          )
      }
    case Actions.REMOVE_SESSION: {
      // Brackets are used for individual block scopes (newSessions conflict)
      const newSessions = sessions.filter(
        (session) => session.id !== action.payload
      )
      return {
        ...state,
        sessions: newSessions
      }
    }
    case Actions.EXTEND_SESSION: {
      // Brackets are used for individual block scopes (newSessions conflict)
      const newSessions = sessions.map((session) => {
        if (session.id === action.payload.id) {
          return {
            ...session,
            ...transformExpiration(action.payload.expiration)
          }
        }
        return session
      })
      return { ...state, sessions: newSessions }
    }
    case Actions.UPDATE_TIMERS:
      return {
        ...state,
        sessions: sessions.map(decreaseTimer)
      }
    default:
      throw new Error('Unknown action type')
  }
}

export const useSessions = ({ onExpire = () => {} } = {}) => {
  const { restaurantInfo } = useBanquetProps()
  // restaurant guid is null in toast admin context but managementSet is not
  const restaurantGuid = restaurantInfo?.restaurant?.guid
  const [{ sessions }, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    _currentRestaurantGuid: restaurantGuid,
    _currentManagementSetGuid: restaurantGuid
      ? restaurantInfo?.managementSet?.guid
      : undefined
  })

  useEffect(() => {
    const interval = setInterval(
      () => dispatch({ type: Actions.UPDATE_TIMERS }),
      1000
    )
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    sessions
      .filter((s) => s.hasExpired)
      .forEach((s) => {
        dispatch({ type: Actions.REMOVE_SESSION, payload: s.id })
        onExpire(s)
      })
  }, [onExpire, sessions])

  return [sessions, dispatch]
}

export const getActiveSession = (sessions) =>
  sessions.find(({ isActive }) => isActive)

export const isAtLeastOneSessionLow = (sessions) =>
  sessions.some(({ isLow }) => isLow)
