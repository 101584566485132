import React, { forwardRef } from 'react'
import cx from 'classnames'
import { number, func, string, oneOfType } from 'prop-types'
import { Variants, Sizes, Contrasts } from '../props'
import {
  VariantPropType,
  SizePropType,
  getBgColor,
  ContrastPropType
} from '../utils'

export const Indicator = forwardRef(
  (
    {
      value,
      variant = Variants.INACTIVE,
      contrast = Contrasts.LIGHT_BACKGROUND,
      size = Sizes.SMALL,
      onClick = () => {},
      className = '',
      testId = 'indicator',
      title = 'Indicator',
      ...props
    },
    ref
  ) => {
    /**
     * Converting first to String allows to parse for other types (null,
     * undefined, object, array, boolean) to be transformed into string that would result
     * in NaN when converted to Number. E.g.:
     * Number(true) === 1 | String(true) === "true" -> Number("true") === NaN
     * Number(null) === 0 | String(null) === "null" -> Number("null") === NaN
     * With the exception of []:
     * Number([]) === 0 | String([]) === "" -> Number("") === 0
     */
    const convertedValue = Number(String(value))
    const text =
      Number.isNaN(convertedValue) ||
      !Number.isInteger(convertedValue) ||
      convertedValue < 0
        ? 0
        : convertedValue
    const cxSize = {
      'h-10 w-10 type-subhead': size === Sizes.SMALL,
      'h-12 w-12 type-default': size === Sizes.MEDIUM,
      'h-14 w-14 type-headline-5': size === Sizes.LARGE
    }
    return (
      <button
        onClick={onClick}
        ref={ref}
        className={cx(
          'm-1 md:m-0 p-2 hover:bg-darken-4',
          'rounded-full text-white text-center',
          'outline-none focus-visible:shadow-focus',
          cxSize,
          {
            'type-subhead': size === Sizes.SMALL,
            'type-default': size === Sizes.MEDIUM,
            'type-headline-5': size === Sizes.LARGE
          },
          className
        )}
        title={title}
        {...props}
        type='button'
        data-testid={testId}
      >
        <div
          className={cx(
            getBgColor(variant, contrast),
            'rounded-full',
            'transition duration-150 ease-out',
            'flex flex-row justify-center items-center',
            'h-full w-full'
          )}
        >
          {text}
        </div>
      </button>
    )
  }
)

Indicator.propTypes = {
  // A positive integer. If value isn't a valid positive integer, will default to `0`.
  value: oneOfType([number, string]).isRequired,
  // The indicator state (defining the background color). Defaults to `inactive`.
  variant: VariantPropType,
  // Size of the indicator. Defaults to `small`.
  size: SizePropType,
  // Tone of the color based on the background. Defaults to `lightBackground`.
  contrast: ContrastPropType,
  // Function called upon clicking the indicator. Defaults to `noop`.
  onClick: func,
  // Class names to customize the button. Defaults to `empty string`.
  className: string
}
