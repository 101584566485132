import { gql } from '@apollo/client'

export const EXTEND_SESSION = gql`
  mutation ExtendSession($ruleGuid: String!) {
    extendSession(ruleGuid: $ruleGuid) {
      id
      managementSet
      expiration
      justification
      restaurant
    }
  }
`
