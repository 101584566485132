import React, { useEffect, useState } from 'react'
import { bool, func, object, string } from 'prop-types'
import {
  getRemainingTimeState,
  RemainingTimeStates
} from '../../../utils/remainingTime'
import { LeaveSession, SessionEnding, SessionExpired } from '../'

export const ModalController = ({
  session,
  isRemovingSession,
  endRemoveSessionWorkflow,
  onEndSession,
  onExtendSession,
  areButtonsDisabled,
  error
}) => {
  const [isOpen, setOpen] = useState(false)
  const { isActive, restaurant, remainingTime } = session
  const modalProps = {
    isOpen,
    restaurantName: restaurant.name,
    areButtonsDisabled,
    error
  }
  const remainingTimeState = getRemainingTimeState(remainingTime)

  useEffect(() => {
    if (isOpen) return
    if (isActive && remainingTimeState <= RemainingTimeStates.ENDING) {
      setOpen(true)
    }
  }, [isActive, isOpen, remainingTimeState])

  if (isActive && remainingTimeState === RemainingTimeStates.EXPIRED) {
    return <SessionExpired {...modalProps} />
  }

  if (isActive && remainingTimeState === RemainingTimeStates.ENDING) {
    return (
      <SessionEnding
        {...modalProps}
        onLeave={() => {
          onEndSession()
        }}
        onExtend={() => {
          onExtendSession()
          setOpen(false)
        }}
      />
    )
  }

  if (isRemovingSession) {
    return (
      <LeaveSession
        {...modalProps}
        isOpen={isRemovingSession}
        onRequestClose={endRemoveSessionWorkflow}
        onEnd={() => {
          onEndSession()
        }}
        onContinue={endRemoveSessionWorkflow}
      />
    )
  }

  return null
}

ModalController.propTypes = {
  /** Information about the selected session. Needs to be the transformed
   * session, with extra properties (`isActive`, `isLow`, `remainingTime`). */
  session: object.isRequired,
  /** Determines if Leave session modal should be open. */
  isRemovingSession: bool.isRequired,
  /** Whether buttons are disabled or not. Used if an asynchronous operation
   * has been triggered. */
  areButtonsDisabled: bool.isRequired,
  /** An error to display as an alert. Defaults to `null`. */
  error: string,
  /** Function to close the Leave session modal. Should modify
   * `isRemovingSession`. */
  endRemoveSessionWorkflow: func.isRequired,
  /** Callback for when user click `Extend session` button. */
  onExtendSession: func.isRequired,
  /** Callback for when user click `End session` or `Leave session` buttons. */
  onEndSession: func.isRequired
}
