export const Variants = Object.freeze({
  INACTIVE: 'inactive',
  DANGER: 'danger',
  SAFE: 'safe'
})

export const Sizes = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
})

export const Contrasts = Object.freeze({
  LIGHT_BACKGROUND: 'lightBackground',
  DARK_BACKGROUND: 'darkBackground'
})
