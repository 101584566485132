import { Variants } from '@toasttab/buffet-pui-indicator'

const padTime = (time) => String(time).padStart(2, '0')

export const RemainingTimeStates = Object.freeze({
  /** 0 second */
  EXPIRED: 0,
  /** less than 2 minutes */
  ENDING: 1,
  /** less than 10 minutes */
  LOW: 2,
  /** less than an hour */
  SAFE: 3
})

const remainingTimeThresholds = {
  [RemainingTimeStates.ENDING]: 0, // greater than 0 second
  [RemainingTimeStates.LOW]: 2 * 60, // greater than 2 minutes
  [RemainingTimeStates.SAFE]: 10 * 60 // greater than 10 minutes
}

/**
 * As we want to display a timer instead of actual time (e.g. if remaining time
 * is an hour, we want to display 60:00 instead of 01:00:00), we can't use the
 * Intl.DateTimeFormat API.
 */
/**
 * Format remaining time in a user friendly way, as a timer.
 * As we want to display a timer instead of actual time (e.g. if remaining time
 * is an hour, we want to display 60:00 instead of 01:00:00), we can't use the
 * Intl.DateTimeFormat API.
 * @param {number} remainingTime In seconds.
 * @returns Formatted timer. Defaults to `00:00` if input is invalid.
 */
export const formatRemainingTime = (remainingTime) => {
  if (!Number.isInteger(remainingTime)) {
    return '00:00'
  }
  /**
   * `.floor()` instead of `.ceil()` to round down and give rooms for seconds.
   */

  const hours = Math.floor(remainingTime / 60 / 60)
  const minutes = Math.floor(remainingTime / 60 - hours * 60)
  const seconds = remainingTime - hours * 3600 - minutes * 60

  const moreThanOneHourInSeconds = remainingTime >= 3600

  return moreThanOneHourInSeconds
    ? `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`
    : `${padTime(minutes)}:${padTime(seconds)}`
}

/**
 * Expiration is a timestamp generated by the back-end, expressed in seconds.
 * @param {number} expiration Timestamp in seconds.
 * @returns Remaining time in seconds. Defaults to `0` if input is invalid.
 */
export const transformExpirationToRemainingTime = (expiration) => {
  if (!Number.isInteger(expiration)) {
    return 0
  }
  /**
   * `.ceil()` instead of `.floor()` to round up, safer for remaining time. It
   * prevents from getting remaining time to be 60:01, because the expiration is
   * in seconds, while `.now()` is in milliseconds. Rounding down would add 1
   * entire second, even when there's 1 millisecond, to the remaining time.
   */
  const result = expiration - Math.ceil(Date.now() / 1000)
  return result < 0 ? 0 : result
}

/**
 * Check state for remainingTime from `RemainingTimeStates` enumeration
 * (`expired`, `ending`, `low`, `safe`).
 * @param {number} remainingTime In seconds.
 * @returns {RemainingTimeStates} The state of the remaining time.
 */
export const getRemainingTimeState = (remainingTime) =>
  Object.values(remainingTimeThresholds).filter(
    (value) => remainingTime > value
  ).length

/**
 * Determine which variant to use with `buffet-pui-indicator` components.
 * @param {number} remainingTime In seconds.
 * @returns Variant to use with `buffet-pui-indicator` components.
 * Defaults to `Variants.DANGER` if input is invalid.
 */
export const getIndicatorVariantFromRemainingTime = (remainingTime) => {
  if (!Number.isInteger(remainingTime)) {
    return Variants.INACTIVE
  }
  return getRemainingTimeState(remainingTime) === RemainingTimeStates.LOW
    ? Variants.DANGER
    : Variants.SAFE
}
