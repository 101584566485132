import cx from 'classnames'
import { oneOf } from 'prop-types'
import { Sizes, Variants, Contrasts } from './props'

export const VariantPropType = oneOf(Object.values(Variants))
export const SizePropType = oneOf(Object.values(Sizes))
export const ContrastPropType = oneOf(Object.values(Contrasts))

/**
 * For Tailwind purge mechanism, we need to keep the utilities as they are, and
 * not try to build them.
 */
export const getBgColor = (
  variant = Variants.INACTIVE,
  contrast = Contrasts.LIGHT_BACKGROUND
) => {
  return cx({
    'bg-success-75':
      variant === Variants.SAFE && contrast === Contrasts.LIGHT_BACKGROUND,
    'bg-error-75':
      variant === Variants.DANGER && contrast === Contrasts.LIGHT_BACKGROUND,
    'bg-gray-50':
      variant === Variants.INACTIVE && contrast === Contrasts.LIGHT_BACKGROUND,
    'bg-success-0':
      variant === Variants.SAFE && contrast === Contrasts.DARK_BACKGROUND,
    'bg-error-0':
      variant === Variants.DANGER && contrast === Contrasts.DARK_BACKGROUND,
    'bg-gray-0':
      variant === Variants.INACTIVE && contrast === Contrasts.DARK_BACKGROUND
  })
}
