import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { AppSetup } from './components/App'
import './index.css'

const lifecycles = banquetSingleSpaReact({
  rootComponent: AppSetup,
  cssScope: 'data-cs',
  singleSpaCssLifecycles,
  sentry: {
    publicKey: 'c598a93925f94d19aa07d4a4f7ba2935@o37442',
    projectId: '5738415',
    releaseVersion: process.env.PKG_VERSION
  },
  domElementGetter: () =>
    document.getElementById('single-spa-application:spa-customer-sessions')
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount
export const name = 'spa-customer-sessions'
