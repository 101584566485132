import React from 'react'
import { bool, func, string } from 'prop-types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getCommonModalProps } from '../getCommonModalProps'

export const SessionEnding = ({
  onLeave,
  onExtend,
  isOpen,
  restaurantName,
  areButtonsDisabled,
  error = null
}) => (
  <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEsc={false}
    {...getCommonModalProps()}
    // onRequestClose is omitted to prevent users from exiting modal
  >
    <ModalHeader>Your session is about to end.</ModalHeader>
    <ModalBody>
      <p>
        Your session in <span className='font-bold'>{restaurantName}</span> is
        about to expire in 2 minutes. Extend session to 60 minutes to stay
        signed in or you will be signed out from this restaurant automatically.
      </p>
      {error && (
        <Alert variant='error' showIcon className='mt-4'>
          {error}
        </Alert>
      )}
    </ModalBody>
    <ModalFooter>
      <Button variant='link' onClick={onLeave} disabled={areButtonsDisabled}>
        Leave session
      </Button>
      <Button onClick={onExtend} disabled={areButtonsDisabled}>
        Extend session
      </Button>
    </ModalFooter>
  </Modal>
)

SessionEnding.propTypes = {
  /** Determines if modal is open. */
  isOpen: bool.isRequired,
  /** Name of the restaurant for the selected session. */
  restaurantName: string.isRequired,
  /** Whether buttons are disabled or not. Used if an asynchronous operation
   * has been triggered. */
  areButtonsDisabled: bool.isRequired,
  /** An error to display as an alert. Defaults to `null`. */
  error: string,
  /** Callback for when user click `Extend session` button. */
  onExtend: func.isRequired,
  /** Callback for when user click `Leave session` button. */
  onLeave: func.isRequired
}
