/**
 * Function instead of a static object since we are calling a function to get
 * a specific element from the document, which may not be present at the time
 * this file is read (e.g. testing, storybook, ...).
 */
export const getCommonModalProps = () => ({
  // size: 'xxl',
  className: 'z-modal',
  overlayClassName: 'z-modal'
})
