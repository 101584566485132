import React from 'react'
import { bool, func, string } from 'prop-types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getCommonModalProps } from '../getCommonModalProps'

export const LeaveSession = ({
  onContinue,
  onEnd,
  isOpen,
  onRequestClose,
  restaurantName,
  areButtonsDisabled
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    shouldCloseOnOverlayClick
    shouldCloseOnEsc
    {...getCommonModalProps()}
  >
    <ModalHeader>Leave session</ModalHeader>
    <ModalBody>
      <p>
        You are about to be signed out of{' '}
        <span className='font-bold'>{restaurantName}</span>.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button variant='link' onClick={onContinue} disabled={areButtonsDisabled}>
        Continue session
      </Button>
      <Button
        variant='destructive'
        onClick={onEnd}
        disabled={areButtonsDisabled}
      >
        End session
      </Button>
    </ModalFooter>
  </Modal>
)

LeaveSession.propTypes = {
  /** Determines if modal is open. */
  isOpen: bool.isRequired,
  /** Name of the restaurant for the selected session. */
  restaurantName: string.isRequired,
  /** Whether buttons are disabled or not. Used if an asynchronous operation
   * has been triggered. */
  areButtonsDisabled: bool.isRequired,
  /** Function to close the modal. Should modify `isOpen`. */
  onRequestClose: func.isRequired,
  /** Callback for when user click `Continue session` button. */
  onContinue: func.isRequired,
  /** Callback for when user click `End session` button. */
  onEnd: func.isRequired
}
