import React from 'react'
import { bool, string } from 'prop-types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { LinkButton } from '@toasttab/buffet-pui-buttons'
import { getCommonModalProps } from '../getCommonModalProps'

export const SessionExpired = ({ isOpen, restaurantName }) => (
  <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEsc={false}
    {...getCommonModalProps()}
    // onRequestClose is omitted to prevent users from exiting modal
  >
    <ModalHeader>You have lost access to this restaurant.</ModalHeader>
    <ModalBody>
      <p>
        Your session in <span className='font-bold'>{restaurantName}</span> has
        expired. Submit a new request to start a new session.
      </p>
    </ModalBody>
    <ModalFooter>
      <LinkButton href='/toast/admin'>Toast Administration</LinkButton>
    </ModalFooter>
  </Modal>
)

SessionExpired.propTypes = {
  /** Information about the selected session. Needs to be the transformed
   * session, with extra properties (`isActive`, `isLow`, `remainingTime`). */
  isOpen: bool.isRequired,
  /** Name of the restaurant for the selected session. */
  restaurantName: string.isRequired
}
