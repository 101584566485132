import React, { useEffect, useState } from 'react'
import { useQuery, NetworkStatus } from '@apollo/client'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Dropdown } from '@toasttab/buffet-pui-dropdowns'
import { Indicator, Variants } from '@toasttab/buffet-pui-indicator'
import { GET_SESSIONS } from '../../api/queries'
import {
  Actions,
  isAtLeastOneSessionLow,
  useSessions
} from '../../hooks/useSessions'
import {
  SessionsActions,
  SessionsControllers,
  SessionsProvider,
  ToastAdminRedirect
} from '../SessionsActions'

const getVariant = (sessions, isOneLow) => {
  if (!sessions || sessions.length === 0) {
    return Variants.INACTIVE
  }
  return isOneLow ? Variants.DANGER : Variants.SAFE
}

export const sessionUpdateInterval = 5 * 60 * 1000 // 5 minutes

export const App = () => {
  const size = useScreenSize()
  const { showSnackBar } = useSnackBar()
  const [isModalOpen, setModalOpen] = useState(false)
  const [sessions, dispatch] = useSessions({
    onExpire(session) {
      showSnackBar(`Your session in ${session.restaurant.name} expired.`, {
        onDismiss: () => {}
      })
    }
  })
  const { loading, error, networkStatus } = useQuery(GET_SESSIONS, {
    pollInterval: sessionUpdateInterval,
    notifyOnNetworkStatusChange: true, //Needed with pollInterval to call onCompleted everytime we receive new data
    onCompleted(data) {
      if (
        data.sessions.length === 0 &&
        !/^\/toast\/admin/.test(window.location.pathname)
      ) {
        window.location.assign('/toast/admin')
      }
      dispatch({ type: Actions.SET_SESSIONS, payload: data.sessions })
    },
    onError(error) {
      console.warn(error)
    }
  })
  const isOneLow = isAtLeastOneSessionLow(sessions)
  const variant = getVariant(sessions, isOneLow)

  useEffect(() => {
    if (!isModalOpen) return
    if (size >= ScreenSize.MD) {
      setModalOpen(false)
    }
  }, [isModalOpen, size])

  if (loading && networkStatus !== NetworkStatus.poll) {
    return (
      <div title='Loading customer sessions'>
        <MerryGoRound className='m-2' size='xxs' testId='loading-sessions' />
      </div>
    )
  }

  if (error) {
    return (
      <div title='An error occurred while loading sessions'>
        <WarningOutlineIcon
          size='sm'
          className='text-warning m-2'
          testId='error-sessions'
          aria-label='Error loading sessions'
        />
      </div>
    )
  }

  return (
    <SessionsProvider sessions={sessions} dispatch={dispatch}>
      <SessionsControllers />
      {size < ScreenSize.MD ? (
        <>
          <Indicator
            onClick={() => setModalOpen(true)}
            value={sessions.length}
            testId='sessions-indicator'
            title='Customer sessions indicator'
            variant={variant}
          />
          <Modal
            testId='sessions-actions-modal'
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
          >
            <ModalHeader>Manage sessions</ModalHeader>
            <ModalBody>
              <SessionsActions />
            </ModalBody>
            <ModalFooter>
              <ToastAdminRedirect />
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <Dropdown
          testId='sessions-actions-popover'
          showArrow
          placement='bottom-end'
          renderToggle={(props) => (
            <Indicator
              {...props}
              value={sessions.length}
              testId='sessions-indicator'
              title='Customer sessions indicator'
              variant={variant}
            />
          )}
        >
          <SessionsActions />
        </Dropdown>
      )}
    </SessionsProvider>
  )
}
