import React, { useState, Fragment } from 'react'
import cx from 'classnames'
import { arrayOf, func, object } from 'prop-types'
import { useMutation } from '@apollo/client'

import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { ColorDot, Variants } from '@toasttab/buffet-pui-indicator'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import {
  formatRemainingTime,
  getIndicatorVariantFromRemainingTime,
  getRemainingTimeState,
  RemainingTimeStates
} from '../../utils/remainingTime'
import { Actions, getActiveSession } from '../../hooks/useSessions'
import { EXTEND_SESSION, DELETE_SESSION } from '../../api/mutations'
import { ModalController } from '../Modals'
import { AutorenewIcon, CloseIcon } from '@toasttab/buffet-pui-icons'

const PreparedColorDot = ({ session }) => {
  const { isActive, remainingTime } = session
  const variant = isActive
    ? getIndicatorVariantFromRemainingTime(remainingTime)
    : Variants.INACTIVE
  const title = !isActive
    ? 'This is not your current session.'
    : getRemainingTimeState(remainingTime) <= RemainingTimeStates.LOW
    ? 'Your current session will soon expire.'
    : 'This is your current session.'
  return (
    <div title={title} className='flex items-center flex-none mr-2 md:mr-0'>
      <ColorDot
        variant={variant}
        testId={`session-indicator-${session.restaurant.guid}`}
      />
    </div>
  )
}

const RemainingTime = ({ session }) => {
  const { isActive, remainingTime, restaurant } = session
  const willActiveExpire =
    isActive &&
    getRemainingTimeState(remainingTime) <= RemainingTimeStates.ENDING
  const title = willActiveExpire ? 'Remaining time is low.' : 'Remaining time.'

  return (
    <p
      className={cx('flex items-center flex-none mx-2 md:mx-0', {
        'text-error': willActiveExpire
      })}
      title={title}
      data-testid={`remaining-time-${restaurant.guid}`}
    >
      {formatRemainingTime(remainingTime)}
    </p>
  )
}

const ExtendButton = ({ disabled, testId, onClick }) => {
  const size = useScreenSize()
  const commonProps = {
    className: 'flex-none',
    testId,
    disabled,
    onClick
  }

  if (size < ScreenSize.MD) {
    return (
      <IconButton
        icon={<AutorenewIcon aria-label='Extend' />}
        {...commonProps}
      />
    )
  }
  return (
    <Button variant='link' size='sm' {...commonProps}>
      Extend
    </Button>
  )
}

const RestaurantName = ({ session }) => {
  const { restaurant, id } = session
  const { name } = restaurant

  return (
    <a
      className='flex items-center flex-auto truncate text-link hover:text-primary-100 outline-none focus-visible:shadow-focus rounded-lg'
      title={`Switch to this restaurant (${name}).`}
      href={`/toast/admin/render/customer-access/switch/${id}`}
    >
      {name}
    </a>
  )
}

export const ToastAdminRedirect = () => (
  <Button
    as='a'
    variant='link'
    size='sm'
    href='/toast/admin'
    title='Go back to Toast Administration portal'
  >
    Toast Administration
  </Button>
)

const SessionsContext = React.createContext()

const useSessionsContext = () => {
  const context = React.useContext(SessionsContext)

  if (context == null) {
    throw new Error('SessionsContext not found')
  }

  return context
}

export const SessionsProvider = ({ children, dispatch, sessions }) => {
  const { showErrorSnackBar } = useSnackBar()
  const [isRemovingSession, setRemovingSession] = useState(null)
  const [error, setError] = useState(null)
  const activeSession = getActiveSession(sessions)

  const [extendSession, { loading: extendingSession }] = useMutation(
    EXTEND_SESSION,
    {
      onCompleted: (data) =>
        dispatch({
          type: Actions.EXTEND_SESSION,
          payload: data.extendSession
        }),
      onError: (e) => {
        const errorExtending =
          'There was an error extending your session. Please try again or contact support.'
        showErrorSnackBar(errorExtending)
        setError(errorExtending)
        console.warn(e)
      }
    }
  )
  const [deleteSession, { loading: deletingSession }] = useMutation(
    DELETE_SESSION,
    {
      onCompleted: (data) => {
        setRemovingSession(null)
        dispatch({
          type: Actions.REMOVE_SESSION,
          payload: data.deleteSession
        })
        // Only redirect if we delete the current session
        activeSession?.id === data.deleteSession &&
          window.location.assign('/toast/admin')
      },
      onError: (e) => {
        const errorDeleting =
          'There was an error removing your session. Please try again or contact support.'
        setRemovingSession(null)
        showErrorSnackBar(errorDeleting)
        setError(errorDeleting)
        console.warn(e)
      }
    }
  )

  const value = {
    sessions,
    onExtendSession: (session) =>
      extendSession({ variables: { ruleGuid: session.id } }),
    onEndSession: (session) =>
      deleteSession({ variables: { ruleGuid: session.id } }),
    areButtonsDisabled: deletingSession || extendingSession,
    isRemovingSession: (session) => session.id === isRemovingSession,
    onStartRemoveSessionWorkflow: (session) => setRemovingSession(session.id),
    onEndRemoveSessionWorkflow: () => setRemovingSession(null),
    error
  }
  return (
    <SessionsContext.Provider value={value}>
      {children}
    </SessionsContext.Provider>
  )
}
export const SessionsActions = () => {
  const {
    sessions,
    areButtonsDisabled,
    onExtendSession,
    onStartRemoveSessionWorkflow
  } = useSessionsContext()
  return (
    // Use modal for mobile view
    <div className='md:max-w-md lg:max-w-lg xl:max-w-xl xxl:max-w-xxl'>
      {sessions.length === 0 ? (
        <p className='p-4 whitespace-nowrap'>You don't have any session yet.</p>
      ) : (
        <ul
          className='md:pt-1 md:pb-3 overflow-y-auto'
          style={{ maxHeight: '320px' }}
        >
          {sessions.map((session) => (
            <li
              key={`row-${session.restaurant.guid}`}
              className='flex flex-row justify-between items-stretch bg-transparent md:px-4 md:space-x-2'
              data-testid={`row-${session.restaurant.guid}`}
            >
              <PreparedColorDot session={session} />
              <RestaurantName session={session} />
              <div className='w-0 md:w-20 flex-grow-0 flex-shrink'></div>
              <RemainingTime session={session} />
              <ExtendButton
                testId={`extend-${session.restaurant.guid}`}
                disabled={areButtonsDisabled}
                onClick={() => onExtendSession(session)}
              />
              <IconButton
                className='flex-none'
                icon={<CloseIcon aria-label='Remove' />}
                testId={`remove-${session.restaurant.guid}`}
                disabled={areButtonsDisabled}
                onClick={() => onStartRemoveSessionWorkflow(session)}
              />
            </li>
          ))}
        </ul>
      )}
      <div className='hidden md:block rounded-b border-t p-2 pb-0'>
        <ToastAdminRedirect />
      </div>
    </div>
  )
}

SessionsActions.propTypes = {
  /** Information about the available sessions to manage. Needs to be the
   * transformed session, with extra properties
   * (`isActive`, `isLow`, `remainingTime`). */
  sessions: arrayOf(object).isRequired,
  /** Function to interactive with the `useSession` reducer. */
  dispatch: func.isRequired
}

export const SessionsControllers = () => {
  const {
    sessions,
    error,
    areButtonsDisabled,
    onExtendSession,
    onEndSession,
    onEndRemoveSessionWorkflow,
    isRemovingSession
  } = useSessionsContext()
  return (
    <>
      {sessions.map((session) => (
        <ModalController
          key={`modal-${session.restaurant.guid}`}
          session={session}
          isRemovingSession={isRemovingSession(session)}
          endRemoveSessionWorkflow={onEndRemoveSessionWorkflow}
          onExtendSession={() => onExtendSession(session)}
          onEndSession={() => onEndSession(session)}
          areButtonsDisabled={areButtonsDisabled}
          error={error}
        />
      ))}
    </>
  )
}
